// extracted by mini-css-extract-plugin
export var about = "blog-author-module--about--8d263";
export var authorImg = "blog-author-module--author-img--d38ea";
export var authorSocials = "blog-author-module--author-socials--481a4";
export var heading = "blog-author-module--heading--ee1e0";
export var headingBg = "blog-author-module--heading-bg--16af2";
export var headingInner = "blog-author-module--heading-inner--089f5";
export var postFooter = "blog-author-module--post-footer--c1ba0";
export var postLine = "blog-author-module--post-line--f443c";
export var shareBlock = "blog-author-module--share-block--67d41";
export var socials = "blog-author-module--socials--a88d6";