import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import * as s from "../assets/css/blog-author.module.css";
import Layout from "../components/layout";
import Post from "../components/post/post";
import Seo from "../components/seo";
import "../assets/css/@wordpress/block-library/build-style/style.css";
import "../assets/css/@wordpress/block-library/build-style/theme.css";

const BlogAuthorTemplate = ({ data: { author, posts }, location }) => {
  
  let avatarUrl = author.avatar.url.replace(`s=${author.avatar.size}`, "s=256");

  return (
    <Layout>
      <Seo title={author.name} description={author.description} location={location}/>

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Person"
      >
        <section className={s.heading}>
          <div className={s.headingBg}></div>
          <div className="container">
            <div className={`d-flex align-items-start ${s.headingInner}`}>
              <img src={avatarUrl} alt={author.name} className={s.authorImg} />
              <div>
                <h1 itemProp="headline">{parse(author.name)}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className={s.about}>
          <div className="container">
            <h3>ABOUT</h3>
            <p>{author.description}</p>
          </div>
        </section>
        <section className={s.postLine}></section>
        <div className="container">
          <div className="row">
            {posts?.nodes?.map(post => {
              return (
                <div className="col-12 col-md-6 col-xl-4" key={post.uri}>
                  <Post post={post} />
                </div>
              )
            })}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default BlogAuthorTemplate;

export const pageQuery = graphql`
  query BlogAuthorById(
    $id: String!
    $authorId: Int!
  ) {
    author: wpUser(id: {eq: $id}) {
      avatar {
        size
        url
      }
      name
      description
      email
      slug
      uri
      seo {
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
    posts: allWpPost(
      sort: {fields: [date], order: DESC}
      filter: {authorDatabaseId: {eq: $authorId}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
